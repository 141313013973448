<!-- 申请借支 -->
<template>
    <div>
        <!-- <van-nav-bar title="申请借支" left-text="返回" left-arrow @click-left="onClickLeft" /> -->
        <div class="ApplyMoney">
            <div v-if="flag == 2"></div>
            <div v-else-if='flag'>
                <div class="staff_info" style="padding-button:0">
                    <div class="title">在职信息</div>
                    <div class="username">
                        <div class="left">姓名</div>
                        <div class="right">{{ list.jobseeker_name }}</div>
                    </div>
                    <div class="username">
                        <div class="left">
                            手机号码
                        </div>
                        <div class="right">{{ list.mobile }}</div>
                    </div>
                    <div class="username">
                        <div class="left">
                            身份证号
                        </div>
                        <div class="right">{{ list.card_num }}</div>
                    </div>
                    <div class="username">
                        <div class="left">
                            在职工厂
                        </div>
                        <div class="right">{{ list.incumbent_factory }}</div>
                    </div>
                    <div class="username">
                        <div class="left">
                            职位名称
                        </div>
                        <div class="right">{{ list.incumbent_job }}</div>
                    </div>
                    <div class="username">
                        <div class="left">
                            入职日期
                        </div>
                        <div class="right">{{ list.incumbent_time }}</div>
                    </div>
                    <div class="username" v-if="list.incumbent_day > -1">
                        <div class="left">
                            在职天数
                        </div>
                        <div class="right">{{ list.incumbent_day }}天</div>
                    </div>
                </div>
                <div class="money_info">
                    <div class="title">借支信息</div>
                    <div class="username">
                        <div class="left">
                            借支金额<span>{{`(最高借支${list.advanced_payment.max_money}元)`}}</span>
                        </div>
                        <div class="right">
                            <input placeholder="请输入" v-model="list.money" @input="list.money = list.money.replace(/[^\d]/g, '').replace(/^0{1,}/g,'')" maxlength="4" />
                            <!-- {{ list.money || '请选择' }} -->
                        </div>
                        <!-- <div class="right_icon" style="color:#C8C9CC">
                            <van-icon name="arrow" />
                        </div> -->
                    </div>
                    <div class="note_box">
                        <div class="left">
                            借支原因
                        </div>
                        <div class='note'>
                            <van-field v-model="note" rows="2" autosize :border="true" type="textarea" maxlength="100" placeholder="请输入借支原因" show-word-limit />
                        </div>
                    </div>
                </div>
                <!--  -->
                <div class="upload_box" v-if='list.advanced_payment && list.advanced_payment.attachment_enable'>
                    <div class="upload_title">
                        <div>上传附件</div>
                        <div class="font24">
                            <span style="color:#0abbb9">{{fileList.length}}</span>/3
                        </div>
                    </div>
                    <div class="upload_imgs">
                        <van-uploader v-model="fileList" :before-delete='before_delete' :after-read='after_read' :max-count="3">
                            <template #preview-cover="{ index }">
                                <div class="preview-cover van-ellipsis">{{ getNum(base64ListSize[index]) }}</div>
                            </template>
                        </van-uploader>
                    </div>
                    <div v-if='list.advanced_payment.attachment_note' class="remark">
                        注： {{list.advanced_payment.attachment_note}}
                    </div>
                </div>
                <van-popup v-model="showPicker" round position="bottom">
                    <van-picker show-toolbar :columns="columns" @cancel="showPicker = false" @confirm="onConfirm" />
                </van-popup>
                <!-- 底部 -->
                <div class="footer-bottom" v-if="is_show"></div>
                <div class="news_footer" v-if="is_show">
                    <div class="news_footer_on" @click="tosubmit" :class="is_click ? 'd8' : ''">提交</div>
                    <!-- <div v-if="count.audit <= 0" style="background:#ccc">申请借支</div> -->
                </div>
                <!-- <div class="footer">
                    <div @click="tosubmit" :style="is_click ? 'background:#aaa' : ''">提交</div>
                </div> -->
            </div>
            <div v-else>
                <div class="news_wenan">
                    <div class="news_wenan_img"><img class="news_bg_imh" src="../../assets/img/null_index.png" alt=""></div>
                </div>
                <div class="news_wenan_text">
                    <div class="news_wenan_text_on">{{error}}</div>
                </div>
            </div>
            <!-- <div class='err'>
                <van-icon name="clear" color="red" size="40px" />
                <div>
                    {{error}}
                </div>
            </div> -->

        </div>
    </div>

</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import '@/assets/css/index.css'
// import { jobseeker_info, jobseeker_apply } from '@/api/ApplyMoney'
// import { getToken } from "@/api/login";
import { Notify } from 'vant';
import Exif from 'exif-js'
export default {
    //import引入的组件需要注入到对象中才能使用
    components: {},
    data() {
        //这里存放数据
        return {
            list: {},
            note: "",
            fileList: [],
            token: "",
            showPicker: false,
            columns: [],
            base64List: [],
            base64ListSize: [], // 上传文件的size
            error: '',
            flag: 2,
            remark: '注：请上传手持本人身份证照片和借条图片！',
            is_borrowing_list: false, // 不是从借支列表过来的
            is_click: false,
            is_show: true,
            docmHeight: window.innerHeight, //默认屏幕高度
            showHeight: window.innerHeight, //实时屏幕高度
        };
    },
    watch: {
        showHeight: function (newValue) {
            if (this.isAndroidOrIOS() == 'android') {
                if (this.docmHeight > newValue) {
                    this.is_show = false
                } else {
                    this.is_show = true
                }
            }
        }
    },
    //生命周期 - 创建完成（可以访问当前this实例）
    created() {
        window.onresize = () => {
            return (() => {
                this.showHeight = window.innerHeight;
            })();
        };
        this.$store.commit("getWxSDK");
        this.$store.commit("hideTabbar");
        if (this.$route.query.team_id) {
            localStorage.setItem('team_id', this.$route.query.team_id)
            localStorage.setItem('factory_id', this.$route.query.factory_id)
        }
        this.onload()

    },
    // 判断是否是从借支列表过来的，是的话则返回上一页，不是则跳转到home页面
    beforeRouteEnter(to, from, next) {
        next(vm => {
            if (from.name == 'BorrowingList') {
                vm.is_borrowing_list = true;
            } else {
                vm.is_borrowing_list = false;
            }
        });
    },
    //方法集合
    methods: {

        getQuery(variable) {
            var query = unescape(window.location.href);
            var lengths = query.split("?");
            var vars = lengths[lengths.length - 1].split("&");
            for (var i = 0; i < vars.length; i++) {
                var pair = vars[i].split("=");
                if (pair[0] == variable) {
                    return pair[1];
                }
            }
            return false;
        },
        isAndroidOrIOS() {
            var u = navigator.userAgent;
            var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
            var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
            if (isAndroid) {
                return 'android';
            }
            if (isiOS) {
                return 'ios';
            }
            return false;
        },
        // 返回到上一页
        onClickLeft() {
            if (this.is_borrowing_list) {
                this.$router.go(-1)
            } else {
                this.$router.replace({ path: '/home' })
            }
        },
        onload() {
            this.Request('client/jobseeker/info', 'get', { team_id: localStorage.getItem('team_id'), is_team_advanced_payment_config: 1 }).then(res => {
                if (res.status == 0) {
                    if (res.data.incumbent_factory_id == localStorage.getItem('factory_id') || !!localStorage.getItem('factory_id') == false || !this.getQuery('factory_id')) {
                        this.list = res.data;
                        if (!!this.list.advanced_payment && !!this.list.advanced_payment.max_money) {
                            for (let i = 100, k = 1; i * k <= this.list.advanced_payment.max_money; k++) {
                                this.columns.push(i * k)
                            }
                        }
                        this.flag = 1
                        if (res.data.advanced_payment.deny_advanced_payment == 1) {
                            this.error = res.data.advanced_payment.deny_advanced_payment_msg
                            this.flag = 0
                        }
                    } else {
                        this.error = '抱歉，您扫描的是其他工厂的借支码，请扫描您在职工厂的借支码进行申请借支'
                        this.flag = 0
                    }

                } else {
                    this.error = res.msg
                    this.flag = 0
                }

            })
        },
        onConfirm(value) {
            this.$set(this.list, 'money', value)
            this.showPicker = false
        },
        tosubmit() {
            if (this.is_click) return
            if (this.isnull()) {
                this.is_click = true
                var data = {
                    team_id: localStorage.getItem('team_id'),
                    money: this.list.money,
                    note: this.note,
                    imgs: JSON.stringify(this.base64List),
                }
                this.Request('client/advanced_payment/info', 'post', data).then(res => {
                    if (res.status == 0) {
                        this.$store.state.refreshList = true // 申请成功后列表需要刷新一下
                        this.$router.replace({ path: '/subSuccess', query: { type: 'ApplyMoney' } })
                    } else {
                        this.error = res.msg
                        this.flag = false
                    }
                    this.is_click = false
                }).catch(() => {
                    this.is_click = false
                })
            }
        },
        getBase64(file) {
            return new Promise((resolve, reject) => {
                let reader = new FileReader();
                let imgResult = "";

                reader.readAsDataURL(file);
                reader.onload = function () {
                    imgResult = reader.result;
                };

                reader.onerror = function (error) {
                    reject(error);
                };
                reader.onloadend = function () {
                    resolve(imgResult);
                };

            });
        },
        // 处理图片
        imgPreview(file) {
            let self = this
            let Orientation
            //去获取拍照时的信息，解决拍出来的照片旋转问题   npm install exif-js --save   这里需要安装一下包
            Exif.getData(file, function () {
                Orientation = Exif.getTag(this, 'Orientation')
            })
            // 看支持不支持FileReader
            if (!file || !window.FileReader) return
            if (/^image/.test(file.type)) {
                // 创建一个reader
                let reader = new FileReader()
                // 将图片2将转成 base64 格式
                reader.readAsDataURL(file)
                // 读取成功后的回调
                reader.onloadend = function () {
                    let result = this.result
                    let img = new Image()
                    img.src = result
                    //判断图片是否大于500K,是就直接上传，反之压缩图片
                    if (this.result.length <= 1024 * 1024) {
                        // 上传图片
                        let len = self.base64ListSize.length
                        if (Number(self.showSize(this.result)) > 3 * 1024) {
                            self.fileList[len].status = 'failed'
                            self.fileList[len].message = '上传失败'
                            Notify('附件图片过大！');
                        } else {
                            self.base64List.push(this.result.split(",")[1])
                            self.base64ListSize.push(self.showSize(this.result))
                        }
                    } else {
                        img.onload = function () {
                            let data = self.compress(img, Orientation)
                            // 上传图片
                            let len = self.base64ListSize.length
                            if (Number(self.showSize(data)) > 3 * 1024) {
                                self.fileList[len].status = 'failed'
                                self.fileList[len].message = '上传失败'
                                Notify('附件图片过大！');
                            } else {
                                self.base64List.push(data.split(",")[1])
                                self.base64ListSize.push(self.showSize(data))
                            }
                        }
                    }

                }
            }
        },
        // 获取base64图片大小，返回kb数字
        showSize(base64url) {
            //把头部去掉
            let str = base64url.replace('data:image/png;base64,', '');
            // 找到等号，把等号也去掉
            let equalIndex = str.indexOf('=');
            if (str.indexOf('=') > 0) {
                str = str.substring(0, equalIndex);
            }
            // 原来的字符流大小，单位为字节
            let strLength = str.length;
            // 计算后得到的文件流大小，单位为字节
            let fileLength = parseInt(strLength - (strLength / 8) * 2);
            // 由字节转换为kb
            let size = "";
            size = (fileLength / 1024).toFixed(2);
            let sizeStr = size + ""; //转成字符串
            let index = sizeStr.indexOf("."); //获取小数点处的索引
            let dou = sizeStr.substr(index + 1, 2) //获取小数点后两位的值
            if (dou == "00") { //判断后两位是否为00，如果是则删除00                
                return sizeStr.substring(0, index) + sizeStr.substr(index + 3, 2)
            }
            return size;
        },
        // 压缩图片
        compress(img, Orientation) {
            let canvas = document.createElement('canvas')
            let ctx = canvas.getContext('2d')
            //瓦片canvas
            let tCanvas = document.createElement('canvas')
            let tctx = tCanvas.getContext('2d')
            // let initSize = img.src.length;
            let width = img.width
            let height = img.height
            //如果图片大于四百万像素，计算压缩比并将大小压至400万以下
            let ratio
            if ((ratio = (width * height) / 4000000) > 1) {
                // console.log("大于400万像素");
                ratio = Math.sqrt(ratio)
                width /= ratio
                height /= ratio
            } else {
                ratio = 1
            }
            canvas.width = width
            canvas.height = height
            //    铺底色
            ctx.fillStyle = '#fff'
            ctx.fillRect(0, 0, canvas.width, canvas.height)
            //如果图片像素大于100万则使用瓦片绘制
            let count
            if ((count = (width * height) / 1000000) > 1) {
                // console.log("超过100W像素");
                count = ~~(Math.sqrt(count) + 1) //计算要分成多少块瓦片
                //      计算每块瓦片的宽和高
                let nw = ~~(width / count)
                let nh = ~~(height / count)
                tCanvas.width = nw
                tCanvas.height = nh
                for (let i = 0; i < count; i++) {
                    for (let j = 0; j < count; j++) {
                        tctx.drawImage(img, i * nw * ratio, j * nh * ratio, nw * ratio, nh * ratio, 0, 0, nw, nh)
                        ctx.drawImage(tCanvas, i * nw, j * nh, nw, nh)
                    }
                }
            } else {
                ctx.drawImage(img, 0, 0, width, height)
            }
            //修复ios上传图片的时候 被旋转的问题
            if (Orientation != '' && Orientation != 1) {
                switch (Orientation) {
                    case 6: //需要顺时针（向左）90度旋转
                        this.rotateImg(img, 'left', canvas)
                        break
                    case 8: //需要逆时针（向右）90度旋转
                        this.rotateImg(img, 'right', canvas)
                        break
                    case 3: //需要180度旋转
                        this.rotateImg(img, 'right', canvas) //转两次
                        this.rotateImg(img, 'right', canvas)
                        break
                }
            }
            //进行最小压缩
            let ndata = canvas.toDataURL('image/jpeg', 0.6)
            tCanvas.width = tCanvas.height = canvas.width = canvas.height = 0;
            return ndata
        },
        // 旋转图片
        rotateImg(img, direction, canvas) {
            //最小与最大旋转方向，图片旋转4次后回到原方向
            const min_step = 0
            const max_step = 3
            if (img == null) return
            //img的高度和宽度不能在img元素隐藏后获取，否则会出错
            let height = img.height
            let width = img.width
            let step = 2
            if (step == null) {
                step = min_step
            }
            if (direction == 'right') {
                step++
                //旋转到原位置，即超过最大值
                step > max_step && (step = min_step)
            } else {
                step--
                step < min_step && (step = max_step)
            }
            //旋转角度以弧度值为参数
            let degree = (step * 90 * Math.PI) / 180
            let ctx = canvas.getContext('2d')
            switch (step) {
                case 0:
                    canvas.width = width
                    canvas.height = height
                    ctx.drawImage(img, 0, 0)
                    break
                case 1:
                    canvas.width = height
                    canvas.height = width
                    ctx.rotate(degree)
                    ctx.drawImage(img, 0, -height)
                    break
                case 2:
                    canvas.width = width
                    canvas.height = height
                    ctx.rotate(degree)
                    ctx.drawImage(img, -width, -height)
                    break
                case 3:
                    canvas.width = height
                    canvas.height = width
                    ctx.rotate(degree)
                    ctx.drawImage(img, -width, 0)
                    break
            }
        },
        //将base64转换为文件
        dataURLtoFile(dataurl) {
            var arr = dataurl.split(','),
                bstr = atob(arr[1]),
                n = bstr.length,
                u8arr = new Uint8Array(n)
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n)
            }
            return new File([u8arr], this.files.name, {
                type: this.files.type
            })
        },
        getNum(num = 0) {
            num = Number(num)
            if (num > 1024) {
                num = (num / 1024).toFixed(2) + 'M'
            } else {
                num = num.toFixed(2) + 'KB'
            }
            return num

        },
        after_read(e) {
            this.imgPreview(e.file)
        },
        before_delete(e) {
            let index = this.fileList.indexOf(e)
            this.base64List.splice(index, 1)
            return true
        },
        isnull() {
            if (!this.list.money) {
                Notify('借支金额不能为空');
                return false
            } else if (this.list.advanced_payment && this.list.advanced_payment.attachment_enable && !this.base64List.length) {
                Notify('附件不能为空');
                return false
            } else if (this.list.money > this.list.advanced_payment.max_money) {
                Notify('借支金额最高限制' + this.list.advanced_payment.max_money + '元');
                return false
            } else {
                return true
            }
        }
    },
};
</script>
<style scoped>
/* @import url(); 引入公共css类 */
.ApplyMoney {
    width: 750px;
    font-size: 30px;
    box-sizing: border-box;
    /* padding-top: 120px; */
    margin-top: 20px;
}

.staff_info,
.money_info,
.upload_box {
    /* width: 700px; */
    /* margin: 0 auto; */
    border-radius: 10px;
    font-size: 28px;
    padding: 30px 30px;
    box-sizing: border-box;
    background: white;
}

.money_info,
.upload_box {
    margin-top: 20px;
}
.username {
    text-align: left;
    border-bottom: 1px solid #f5f5f5;
    display: flex;
    /* height: 80px;
    line-height: 80px; */
    padding: 30px 0;
    align-items: center;
}
.username:last-child {
    border-bottom: 0;
}
.title {
    font-size: 30px;
    text-align: left;
    font-weight: bold;
}
.left {
    width: 150px;
    white-space: nowrap;
    text-align: left;
    display: flex;
    align-items: center;
}
.left span {
    font-size: 20px;
    color: #999;
}
.right {
    width: 75%;
    display: flex;
    justify-content: flex-end;
}
.right input {
    width: 75%;
    overflow: hidden;
    text-overflow: ellipsis;
    border: 0;
    white-space: nowrap;
    text-align: right;
    background: transparent;
}
.placeholder {
    margin-left: 50px;
}
.note {
    border: 2px solid #f5f5f5;
    border-radius: 10px;
    z-index: 100;
    margin-top: 20px;
    overflow: hidden;
}
.note_box {
    padding: 20px 0;
}
.upload_title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto 20px;
    font-weight: 700;
}
.upload_title .font24,
.upload_title span {
    font-size: 24px !important;
    color: #646566;
    font-weight: 400;
}
.upload_imgs {
    text-align: left;
}
.btn {
    position: fixed;
    left: 0;
    bottom: 0;
}
.box {
    height: 20px;
}
.remark {
    font-size: 20px;
    color: #333333;
    text-align: left;
    word-break: break-all;
}
.err {
    padding-top: 200px;
    text-align: center;
}
.err div {
    width: 500px;
    margin: 20px auto;
}
.right_icon {
    display: flex;
    align-items: center;
}
.preview-cover {
    position: absolute;
    bottom: 0;
    box-sizing: border-box;
    width: 100%;
    padding: 8px;
    color: #fff;
    font-size: 24px;
    text-align: center;
    background: rgba(0, 0, 0, 0.3);
}
</style>
